import { getListData } from '@/views/home/services'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const homeSlice = createSlice({
  name: 'home',
  initialState: {},
  reducers: {}
})

export default homeSlice.reducer
